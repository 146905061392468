<template>
    <default-layout>
        <white-loader :loading="loading" />
        <div style="position: relative">
            <header
                style="background: linear-gradient(180deg, rgba(0, 0, 0, 0.80) 3.59%, rgba(133, 11, 11, 0.80) 48.02%); padding: 11px 25px 11px 25px;">
                <h1 class="welcome-message">
                    {{ $t('default.HOME_WELCOME_MESSAGE') }}
                </h1>
            </header>
            <div class="wrap-home-resources">
                <div class="home-resources-content">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-md-12">
                                <div id="carousel-home-resources" class="carousel-home-resources position-relative">
                                    <div class="w-100 px-3 pt-5 pt-sm-4 mx-auto position-absolute d-flex justify-content-start"
                                        style="z-index: 9; left:50%; top: 5%; transform: translate(-50%, -50%)  ; max-width: 700px;">
                                        <mdb-btn tag="a"
                                            href="https://www.youtube.com/watch?v=wR1iYYlnliw&ab_channel=ReddeCastillosyPalaciosdeEspa%C3%B1a"
                                            target="_blank" size="sm" class="spainhn-button"
                                            style="background-color: #ff0000 !important">
                                            <font-awesome-icon icon="fa-play" class=" fs-6 text-white" />
                                        </mdb-btn>
                                    </div>
                                    <carousel-3d :perspective="36" :border="0" :width="700" :height="450"
                                        :controls-visible="true" :space="530" autoplay :autoplayTimeout="5000"
                                        :count="imagenes.length" :display="imagenes.length > 4 ? 5 : imagenes.length">

                                        <slide style="border-radius: 10px;" v-for="(img, index) in imagenes"
                                            :index="index" :key="index">
                                            <template slot-scope="{ index, isCurrent, leftIndex, rightIndex }">
                                                <img :data-index="index"
                                                    :class="{ current: isCurrent, onLeft: (leftIndex >= 0), onRight: (rightIndex >= 0) }"
                                                    class="slider-img-2" :src="img.url" />
                                            </template>
                                        </slide>

                                    </carousel-3d>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

            <div style="position: absolute; top: 65%; width: 100%" class="px-0">
                <mdb-container class="pt-md-4 pt-3">
                    <BuscadorNewDesign :disabled="disabledBtn" @auto-hide-warning="closeWarning" :tooltip="tooltip"
                        :warningInfo="warningInfo" @submit="submit" @input="updateInput"
                        @update-value="(evt) => updateBuscadorInput(evt)" :changeColorBtn="changeColorBtn"
                        :value="buscadorInput" class="mx-auto" style="width: 540px; max-width: 85vw;" ref="pill">
                        <div class="w-100">
                            <div class="d-flex w-100 justify-content-center align-content-center">
                                <h6 class="mr-1" style="text-wrap: balance">{{ $t("default.FILTER.SELECT_DATE_RANGE") }}
                                </h6>
                                <!-- <mdb-btn type="submit"
                                    :disabled="!buscadorInput && (!range.start || !range.end) || buscadorInput?.length < 3"
                                    class="p-2 px-1" color="danger">Buscar</mdb-btn> -->
                                <font-awesome-icon :class="(range?.start && range?.end) ? 'color-corp' : 'text-light'"
                                    @click="limpiarBuscador" style="cursor: pointer; transition: all 0.3s;"
                                    icon="fa-broom"></font-awesome-icon>
                            </div>
                            <vc-date-picker v-model="range" is-range color="red" class="color-corp" style=""
                                :min-date="new Date()" />
                        </div>
                    </BuscadorNewDesign>
                </mdb-container>

            </div>


        </div>

        <!-- <AlgoliaSearch /> -->

        <mdb-container style="padding-inline: 12px;" id="results" class="mx-auto">
            <ToolsComponent
                style="width: 100%; max-width: 780px; margin-left: auto; margin-right: auto; border-radius: 10px; overflow: hidden;" />
        </mdb-container>

        <!-- <minimalist-breadcrumb :title="monumentoNombre" style="margin-bottom: 15px;"/> -->

        <div style="min-height: 500px; height: 100%" v-if="loading">
        </div>

        <div class="container-fluid pt-4 px-1 px-md-3">

            <mdb-row class="gy-3">

                <mdb-col col="12" class="order-3">
                    <h3 class="spainhn-subtitle text-uppercase">{{ $t("default.HOME_NUESTROS_MONUMENTOS") }}</h3>
                    <!-- MONUMENTOS -->
                    <mdb-container class="px-0 px-md-2" fluid v-for="tipo in availableTiposDeMonumento" :key="tipo.value">
                        <div v-if="getMatchingTipoMonumentos(tipo.value).length > 0">

                        <hr class="m-0 mt-1 mx-3">
                            <mdb-badge class="spainhn-header-info text-uppercase"
                                style="background-color: var(--spainhn-rojo-spainhn) !important;">
                                {{ tipo.webName }}
                                <font-awesome-icon :class="{ 'd-none': sugerencias }" style="width: 20px; height: 20px;"
                                    icon="fa fa-location-dot" />
                            </mdb-badge>
                            <div class="d-md-block d-none mb-4">
                                <div class="d-flex justify-content-around px-0 mx-auto w-100 flex-wrap" v-if="!clorian">

                                    <Cards2 v-for="(value, index) in getMatchingTipoMonumentos(tipo.value)" :key="index"
                                        :monument="value.webName" :provincia="value.provincia"
                                        :municipio="value.municipio"
                                        :imageUrl="`${imagenesUrl}/monumentos/${value.oldId}/${value.banner}`"
                                        :id="value._id" :nombreUrl="value.nombreNormalizado"
                                        :text="value.webDescription" role="button" />

                                </div>
                            </div>
                            <mdb-row class="mx-auto d-md-none">
                                <mdb-col class="px-0" v-for="(value, index) in getMatchingTipoMonumentos(tipo.value)" v-bind:key="index">
                                    <CardsGeneral class="mx-auto"
                                        :subtitle="`${value.provincia}, ${value.municipio}`"
                                        :title="value.webName"
                                        :imageUrl="`${imagenesUrl}/monumentos/${value.oldId}/${value.banner}`"
                                        @goToUrl="goToCastillos(value.nombreNormalizado)" type="monumento" />
                                </mdb-col>
                            </mdb-row>
                            <mdb-row class="mx-auto d-md-none">
                                <mdb-col col="12">
                                    <button class="read-more-btn spainhn-gray-background mx-auto mb-1" v-if="tipo.value === 'Castillos'"
                                        @click="() => toggleFullCastles()">
                                        {{ 'Cargar más' }}
                                    </button>
                                </mdb-col>
                            </mdb-row>
                        </div>
                    </mdb-container>
                </mdb-col>

                <mdb-col col="12" class="order-1">
                    <h3 class="spainhn-subtitle text-uppercase">{{ $t("default.HOME_QUE_HACER_Y_VER") }}</h3>
                    <!-- VISITAS -->
                    <mdb-container class="px-0 px-md-2" fluid>

                        <div class="py-2" v-if="visitas.length">
                            <!-- <div class="spainhn-header-info">
                                {{ !sugerencias ? $t("default.HOME_RESULTS_SINGLE_VISITS_LABEL") :
                                    $t("default.HOME_RESULTS_VISITS_LABEL") }}
                                <font-awesome-icon :class="{ 'd-none': sugerencias }" style="width: 20px; height: 20px;"
                                    icon="fa-location-dot" />
                            </div> -->
                            <hr class="m-0 mt-1 mb-2 mx-3">
                            <mdb-badge class="spainhn-header-info text-start" color="danger"
                                style="background-color: var(--spainhn-rojo-spainhn) !important;">
                                {{ !sugerencias ? $t("default.HOME_RESULTS_SINGLE_VISITS_LABEL") :
                                    $t("default.HOME_RESULTS_VISITS_LABEL") }}
                                <font-awesome-icon :class="{ 'd-none': sugerencias }" style="width: 20px; height: 20px;"
                                    icon="fa-location-dot" />
                            </mdb-badge>
                            <!-- This is shown only in medium size screens -->
                            <div class="d-md-flex d-none justify-content-around px-0 mx-auto w-100 flex-wrap"
                                v-if="!clorian">
                                <!-- <template v-if="mostrandoResultados"> -->
                                <ResultsCards v-for="(visita) in visitas" v-bind:key="visita._id"
                                    @goToEntrada="(evt) => goToEntrada({ entradaId: evt.entradaId, monumentoUrl: visita.monumentUrl })"
                                    :entradaId="visita._id" :monumentoNombreURL="visita.webName"
                                    :imageUrl="`${imagenesUrl}/visitas_castillos/${visita.oldId}/${visita.banner}`"
                                    :title="visita.webName" :monument="visita.monumentName"
                                    :apiDeVentasUrl="visita.apiDeVentasActive ? `${visita.apiDeVentasUrl}?idProducto=${visita.oldId}` : ''"
                                    :precioOrientativo="visita?.precioOrientativo ? $t('default.CARDS.START_FROM', { msg: getPrice(visita?.precioOrientativo) }) : ''">
                                </ResultsCards>
                                <!-- </template> -->
                                <!-- <template v-else>
                                    <ResultsCards v-for="(visita, index) in (visitas.filter( v => v.tipoFiltro === 'Visita Guiada'))" 
                                        v-bind:key="index"
                                        @goToEntrada="(evt) => goToEntrada({ entradaId: evt.entradaId, monumentoUrl: visita.castilloNombres?.find(item => item.idioma === $i18n.locale)?.texto })"
                                        :entradaId="visita._id" 
                                        :monumentoNombreURL="visita.nombre"
                                        :imageUrl="`${imagenesUrl}/visitas_castillos/${visita.oldId}/${visita.banner}`"
                                        :title="visita.nombres.find(item => item.idioma === $i18n.locale)?.texto"
                                        :monument="visita.castilloNombres?.find(item => item.idioma === $i18n.locale)?.texto"
                                        :apiDeVentasUrl="visita.apiDeVentasActive ? `${visita.apiDeVentasUrl}?idProducto=${visita.oldId}` : ''"
                                        :precioOrientativo="$t('default.CARDS.START_FROM', { msg: getPrice(visita?.precioOrientativo)}) "></ResultsCards>
                                </template> -->
                            </div>

                            <mdb-row class="mx-auto d-md-none" v-bind:class="{ 'card-container': !showAllVisits }">
                                <mdb-col class="px-0" v-for="(visita) of visitas" v-bind:key="visita._id">
                                    <CardsGeneral class="mx-auto" :title="visita.webName"
                                        :subtitle="`${visita.municipio}, ${visita.provincia}`" :bold="true"
                                        :muted="true" :content="`${visita.monumentName || visita.comunidad}`"
                                        :image-url="`${imagenesUrl}/visitas_castillos/${visita.oldId}/${visita.banner}`"
                                        @goToBuy="goToEntrada({ entradaId: visita._id, monumentoUrl: visita.monumentUrl })"
                                        :buyBtn="true"
                                        @goToUrl="() => goToBuy({ entradaId: visita._id, oldId: visita.oldId, apiDeVentasActive: visita.apiDeVentasActive, apiDeVentasUrl: visita.apiDeVentasUrl })" />
                                </mdb-col>
                            </mdb-row>

                            <mdb-row :class="{ 'd-none': visitas.length === 0 }" class="mx-auto d-md-none">
                                <mdb-col col="12">
                                    <button class="read-more-btn spainhn-gray-background mx-auto mb-0"
                                        @click="() => toggleFullVisits()">
                                        {{ !showAllVisits ? $t('default.HOME_RESULTS_MORE_VISITS2') :
                                            $t('default.HOME_RESULTS_LESS_VISITS2') }}
                                    </button>
                                </mdb-col>
                            </mdb-row>

                        </div>

                    </mdb-container>
                    <!-- EVENTOS -->
                    <mdb-container class="px-0 px-md-2" fluid>

                        <div class="py-2" v-if="eventos2?.length">
                            <hr class="m-0 mt-1 mb-2 mx-3">
                            <mdb-badge class="spainhn-header-info" color="danger"
                                style="background-color: var(--spainhn-rojo-spainhn) !important;">
                                {{ !sugerencias ? $t("default.HOME_RESULTS_SINGLE_EVENTS_LABEL") :
                                    $t("default.HOME_RESULTS_EVENTS_LABEL") }}
                                <font-awesome-icon :class="{ 'd-none': sugerencias }" style="width: 20px; height: 20px;"
                                    icon="fa-location-dot" />
                            </mdb-badge>
                            <!-- This is shown only in medium size screens -->
                            <div class="d-md-flex d-none justify-content-around px-0 mx-auto w-100 flex-wrap"
                                v-if="!clorian">
                                <ResultsCards v-for="(visita, index) in eventos2" v-bind:key="index"
                                    @goToEntrada="(evt) => goToEntrada({ entradaId: evt.entradaId, monumentoUrl: visita.monumentUrl })"
                                    :entradaId="visita._id" :monumentoNombreURL="visita.webName"
                                    :imageUrl="`${imagenesUrl}/visitas_castillos/${visita.oldId}/${visita.banner}`"
                                    :title="visita.webName" :monument="visita.monumentName"
                                    :precioOrientativo="visita?.precioOrientativo && $t('default.CARDS.START_FROM', { msg: getPrice(visita?.precioOrientativo) })"
                                    :apiDeVentasUrl="visita.apiDeVentasActive ? `${visita.apiDeVentasUrl}?idProducto=${visita.oldId}` : ''"
                                    tipo="Evento" :prox-date="visita.fechaInicioEvento" :date="visita.fechas">
                                </ResultsCards>
                            </div>

                            <mdb-row class="mx-auto d-md-none" v-bind:class="{ 'card-container': !showAllEvents }">
                                <mdb-col class="px-0" v-for="(visita, index) of eventos2" v-bind:key="index">
                                    <CardsGeneral class="mx-auto" :title="visita.webName"
                                        :subtitle="`${visita.municipio}, ${visita.provincia}`" :bold="true"
                                        :muted="true" :content="`${visita.monumentName || visita.comunidad}`"
                                        :image-url="`${imagenesUrl}/visitas_castillos/${visita.oldId}/${visita.banner}`"
                                        @goToUrl="() => goToEntrada({ entradaId: visita._id, monumentoUrl: visita.monumentUrl })" />
                                </mdb-col>
                            </mdb-row>

                            <mdb-row :class="{ 'd-none': eventos2.length == 0 }" class="mx-auto d-md-none">
                                <mdb-col col="12">
                                    <button class="read-more-btn spainhn-gray-background mx-auto mb-0"
                                        @click="() => toggleFullEvents()">
                                        {{ $t('default.HOME_RESULTS_MORE_VISITS') }}
                                    </button>
                                </mdb-col>
                            </mdb-row>
                        </div>

                    </mdb-container>
                </mdb-col>

                <mdb-col col="12" class="order-2">
                    <h3 class="spainhn-subtitle text-uppercase"
                        :class="{ 'd-none': (paquetes.length === 0 && servicios.length === 0 && paquetesMonumentos.length === 0) }">
                        {{ $t("default.HOME_NUESTROS_PAQUETES") }}
                    </h3>
                    <!-- PAQUETES -->
                    <mdb-container class="px-0 px-md-2" fluid>
                        <div class="py-2"
                            v-if="paquetes.length > 0 || servicios.length > 0 || paquetesMonumentos.length > 0">
                            <hr class="m-0 mt-1 mb-2 mx-3">
                            <mdb-badge class="spainhn-header-info"
                                style="background-color: var(--spainhn-rojo-spainhn) !important;">
                                {{ $t("default.HOME_RESULTS.SERVICIOS_TITLE") }}
                                <font-awesome-icon :class="{ 'd-none': sugerencias }" style="width: 20px; height: 20px;"
                                    icon="fa-location-dot" />
                            </mdb-badge>

                            <div class="d-md-flex d-none justify-content-around px-0 mx-auto w-100 flex-wrap">
                                <template>
                                    <ResultsCards v-for="paqueteM in paquetesMonumentos" :key="paqueteM._id"
                                        class="mx-auto" :title="paqueteM.webName" :entradaId="paqueteM._id"
                                        :showDefault="false" :monumentoNombreURL="paqueteM.nombreNormalizado"
                                        :monument="$t('default.CARDS.PLACE', { 'msg': `${paqueteM.monumentName}` })"
                                        :precioOrientativo="paqueteM?.precioOrientativo ?
                                            $t('default.CARDS.START_FROM', { msg: `${parseFloat(Number(paqueteM?.precioOrientativo))?.toLocaleString('es-ES', { style: 'currency', currency: 'EUR' })}` }) :
                                            ''"
                                        :imageUrl="`${imagenesUrl}/paquetes_monumentos/${paqueteM.oldId}/${paqueteM.banner}`"
                                        tipo="paquetes_monumentos"
                                        @goToEntrada="() => goToMicrositioPaqueteMonumento({ name: paqueteM.name, id: paqueteM._id })"
                                        @buy="() => goToBuy(paqueteM, true)">
                                    </ResultsCards>
                                    <ResultsCards v-for="product of paquetes.concat(servicios)" :key="product._id"
                                        class="mx-auto" :title="product.webName || product.nombreServicio"
                                        :showDefault="false" :entradaId="product._id"
                                        :monumentoNombreURL="product.webName"
                                        :text="$t('default.CARDS_PLACE', { 'msg': product.place })"
                                        :monument="$t('default.CARDS_PLACE', { 'msg': product.place })"
                                        tipo="paquetes_prestadores"
                                        :precioOrientativo="product?.precioOrientativo && $t('default.CARDS.START_FROM', { msg: `${parseFloat(Number(product?.precioOrientativo))?.toLocaleString('es-ES', { style: 'currency', currency: 'EUR' })}` })"
                                        :imageUrl="`${imagenesUrl}/${product.imageLabel}/${product.oldId ? product.oldId + '/' + encodeURI(product.banner) : product.prestadorId + '/' + product.banner}`"
                                        @goTo="goToEntrada(product)" @goToEntrada="() => goToEntrada(product)"
                                        @buy="() => goToBuy(product)">
                                    </ResultsCards>
                                </template>
                            </div>
                            <mdb-row class="mx-auto d-md-none" v-bind:class="{ 'card-container': !showAllVisits }">
                                <mdb-col class="px-0" v-for="(paqueteM, index) of paquetesMonumentos"
                                    v-bind:key="index">
                                    <CardsGeneral class="mx-auto" :title="paqueteM.name"
                                        :subtitle="`${paqueteM.monumentoId?.comunidad}`" :bold="true" :muted="true"
                                        :content="`${paqueteM.webName}`"
                                        :image-url="`${imagenesUrl}/paquetes_monumentos/${paqueteM.oldId}/${paqueteM.banner}`"
                                        @goToBuy="() => goToMicrositioPaqueteMonumento({ name: paqueteM.name, id: paqueteM._id })"
                                        :buyBtn="true" type="paquetes_monumentos"
                                        @goToUrl="() => goToBuy(paqueteM, true)" />
                                </mdb-col>
                                <mdb-col class="px-0" v-for="producto of paquetes.concat(servicios)"
                                    :key="producto._id">
                                    <CardsGeneral class="mx-auto"
                                        :title="producto.nombres.find(item => item.idioma === $i18n.locale)?.texto"
                                        :subtitle="`${producto.municipio}, ${producto.provincia}`" :bold="true"
                                        :muted="true" :content="`${producto.monumentName || producto.comunidad}`"
                                        :image-url="`${imagenesUrl}/visitas_castillos/${producto.oldId}/${producto.banner}`"
                                        @goToBuy="() => goToEntrada(producto)" type="paquetes_monumentos" :buyBtn="true"
                                        @goToUrl="() => goToBuy(producto)" />
                                </mdb-col>

                            </mdb-row>
                        </div>
                    </mdb-container>
                </mdb-col>

                <mdb-col col="12" class="order-3">
                    <h3 class="spainhn-subtitle text-uppercase">{{ $t("default.HOME_NUESTRAS_RUTAS") }}</h3>
                    <!-- RUTAS -->
                    <mdb-container class="px-0 px-md-2" fluid>
                        <div class="py-2" v-if="rutas">
                            <hr class="m-0 mt-1 mb-2 mx-3">
                            <mdb-badge class="spainhn-header-info"
                                style="background-color: var(--spainhn-rojo-spainhn) !important;">
                                {{ !sugerencias ? $t("default.HOME_RESULTS_NEAR_ROUTES_LABEL") :
                                    $t("default.HOME_RESULTS_ROUTES_LABEL")
                                }}
                                <font-awesome-icon :class="{ 'd-none': sugerencias }" style="width: 20px; height: 20px;"
                                    icon="fa-location-dot" />
                            </mdb-badge>
                            <!-- This is shown only in medium size screens -->
                            <div class="d-md-flex d-none justify-content-around px-0 mx-auto w-100 flex-wrap"
                                v-if="!clorian">
                                <Cards3 v-for="( ruta, index ) in rutas" :key="index" :showSubtitle="false"
                                    :title="ruta.webName" :image-url="`${imagenesUrl}/rutas/logo/${ruta?.rutaId}`"
                                    @goTo="() => goToRoute(ruta._id)" />

                            </div>

                            <mdb-row class="mx-auto d-md-none" v-bind:class="{ 'card-container': !showAllRoutes }">
                                <mdb-col class="px-0" v-for="(ruta, index) of rutas" v-bind:key="index">
                                    <CardsGeneral class="mx-auto" :title="ruta.webName" :bold="true" :muted="true"
                                        :content="`${ruta.duracion ? ruta.duracion?.find(item => item.idioma === $i18n.locale)?.texto : '1 día'}`"
                                        :image-url="`${imagenesUrl}/rutas/logo/${ruta.rutaId}`"
                                        @goToUrl="() => goToRoute(ruta._id)" />
                                </mdb-col>

                            </mdb-row>

                            <mdb-row class="mx-auto d-md-none" :class="{ 'd-none': rutas.length == 0 }">
                                <mdb-col col="12">
                                    <button class="read-more-btn spainhn-gray-background mx-auto mb-1"
                                        @click="() => toggleFullRoutes()">
                                        {{ $t('default.HOME_RESULTS_MORE_ROUTES') }}
                                    </button>
                                </mdb-col>
                            </mdb-row>
                        </div>
                    </mdb-container>
                </mdb-col>
            </mdb-row>

        </div>


        <div class="container-fluid">
            <hr>
            <!-- <h3 class="spainhn-header-info mb-2">
                {{ $t("default.HOME_RESULTS_MAP_LABEL") }}
            </h3> -->
            <div class="mt-3 d-none d-md-block" v-if="isMobile === false">
                <div>
                    <MiniMap class="d-none d-md-block" :variant="false" />
                </div>
            </div>
            <div class="mt-3" v-else>
                <MiniMap idFilter="home-filter" class="d-md-none mx-auto" />
            </div>
        </div>

        <div class="container-fluid p-0 slider-cards">
            <div class="row m-0" style="min-height:70vh">
                <div class="col-xxl-9 col-12 justify-content-center px-3 order-last order-xxl-first py-4 visitas-container"
                    style="min-height:70vh">
                    <div class="pt-1">
                        <slider class="my-3" :tipo="'Evento'" :carouselId="'eventos33'" :images="eventosClusters">
                        </slider>
                        <h3 class="spainhn-title my-auto" v-bind:class="{ 'd-none': eventos.length }">Aún no hay
                            próximas
                            visitas</h3>
                    </div>
                </div>

                <div
                    class="spainhn-solid-red-background col-xxl-3 px-4 px-md-5 order-first order-xxl-last pt-5 pb-3 col-12">
                    <h1 class="spainhn-title-text-2 text-start pt-md-5"><strong>{{
                        $t('default.HOME_NEXT_EVENTS_TITLE') }}</strong></h1>
                    <p class="spainhn-text text-start pe-md-5" style="color:white">{{
                        $t('default.HOME_EVENTS_DESCRIPTION') }}</p>
                    <mdb-btn color="indigo" class="spainhn-button-gray ms-4 mt-2 text-white" size="lg"
                        @click="goToEventos"><strong> {{ $t('default.HOME_ALL_EVENTS_BUTTON') }} </strong></mdb-btn>
                </div>
            </div>
        </div>

        <div class="container-fluid p-0 slider-cards">
            <div class="row m-0" style="min-height:70vh">
                <div class="spainhn-solid-red-background col-12 col-xxl-3 pt-5 pb-3 px-md-5 px-4">
                    <h1 class="spainhn-title-text-2 text-end pt-md-5"><strong>{{
                        $t('default.HOME_NEXT_EXPERIENCES_TITLE') }}</strong></h1>
                    <p class="spainhn-text text-end ps-md-5" style="color:white">{{
                        $t('default.HOME_EXPERIENCES_DESCRIPTION_1') }}</p>
                    <p class="spainhn-text text-end ps-md-5" style="color:white">{{
                        $t('default.HOME_EXPERIENCES_DESCRIPTION_2') }}</p>
                    <mdb-btn color="indigo" class="spainhn-button-gray ms-4 mt-2 text-white" size="lg"
                        @click="goToExperiences"><strong>{{ $t('default.HOME_ALL_EXPERIENCES_BUTTON') }}</strong>
                    </mdb-btn>

                </div>
                <div class=" col-xxl-9 col-12 justify-content-center px-3 py-4 visitas-container"
                    style="min-height:70vh;">
                    <div class="pt-1 mt-2">
                        <ul class="carousel-indicators bg-danger">
                            <!-- <li  v-for="(i,index) in experienciasClusters" :key="index" data-target="#experiencias" :data-slide-to="index"/> -->
                            <li data-target="#experiencias" data-slide-to="0" class="active"></li>
                            <li data-target="#experiencias" data-slide-to="1"></li>
                            <li data-target="#experiencias" data-slide-to="2"></li>
                        </ul>
                        <slider :tipo="'Experiencia'" class="my-3" :carouselId="'experiencias'"
                            :images="experienciasClusters">
                        </slider>
                        <h3 class="spainhn-title my-auto" v-bind:class="{ 'd-none': experiencias.length }">
                            Aún no hay próximas experiencias
                        </h3>
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-3">
            <ComponentsCloud class="p-0" />
        </div>

        <!-- <trade-spoting v-if="monumentoUrl == 'castillo-de-belmonte'"></trade-spoting> -->
    </default-layout>
</template>

<script>
import DefaultLayout from '../layouts/DefaultLayout.vue'
import ApiService from '../services/ApiService';
import ResultsCards from '../components/ResultsCards.vue';
import WhiteLoader from '../components/Loaders/WhiteLoader'
import Cards2 from '../components/Cards2.vue'
import Cards3 from "../components/Cards3.vue";
import CardsGeneral from '../components/CardsGeneral.vue';
import ToolsComponent from '../components/Tools/ToolsComponent.vue';
import BuscadorNewDesign from '../components/BuscadorNewDesign.vue';
import MiniMap from '../components/MiniMap.vue';
import ComponentsCloud from '../components/ComponentsCloud.vue';
import Slider from '../components/Slider.vue';
import { mapState } from 'vuex';
import { mdbContainer, mdbRow, mdbCol } from 'mdbvue'

export default {
    name: 'HomeMobileDesign',
    components: {
        'default-layout': DefaultLayout,
        // cards: Cards,
        'white-loader': WhiteLoader,
        // RecommendationsCard,
        Cards2,
        CardsGeneral,
        ToolsComponent,
        BuscadorNewDesign,
        // TradeSpoting,
        MiniMap,
        // Mapa,
        ComponentsCloud,
        slider: Slider,
        Cards3,
        mdbContainer,
        'mdb-row': mdbRow,
        'mdb-col': mdbCol,
        ResultsCards
    },
    data() {
        return {
            isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
            address: null,
            availableTiposDeMonumento: [],
            availableMonuments: [],
            monumentoUrl: null,
            monumentoId: null,
            entradas: [],
            imagenesUrl: null,
            eventos: [],
            rutas: [],
            clorian: false,
            loading: false,
            clorianPos: null,
            errorResults: false,
            visitas: [],
            eventos2: [],
            paquetesMonumentos: [],
            paramsFilter: {},
            FILES_URL: process.env.VUE_APP_IMAGES_URL,
            defaultImages: [
                {
                    url: 'https://spainheritageimg.s3.eu-west-3.amazonaws.com/files/imagenes/monumentos/144/46940-1s.webp'
                },
                {
                    url: 'https://spainheritageimg.s3.eu-west-3.amazonaws.com/files/imagenes/monumentos/31/2110-imagenes_aereas_3.webp'
                },
                {
                    url: 'https://spainheritageimg.s3.eu-west-3.amazonaws.com/files/imagenes/visitas_castillos/938/VisitaPalaciodelaMagdalena_PALACIO_MAGDALENApng.webp'
                },
            ],
            buscadorOptions: [
                {
                    text: 'Seleccionar Fechas',
                    icon: '/calendar.svg'
                },
                {
                    text: this.$t("default.FILTER.DESTINY_OR_ACTIVITY"),
                    icon: '/pencil.svg'
                }
            ],
            slide: 0,
            currentLocation: {
                lat: null,
                lng: null
            },
            castillos: [],
            castillosProvincia: [],
            palacios: [],
            palaciosProvincia: [],
            showAllCastles: false,
            showAllPalaces: false,
            showAllRoutes: false,
            showAllEvents: false,
            showAllVisits: false,
            showAllServices: false,
            date: new Date(),
            range: {
                start: null,
                end: null
            },
            buscadorInput: '',
            format: {
                'es': 'es-ES',
                'en': 'en-GB',
                'fr': 'fr-FR',
                'de': 'de-DE'
            },
            sugerencias: false,
            error: false,
            slides: 3,
            eventosClusters: [],
            clusterSize: 3,
            experienciasClusters: [],
            experiencias: [],
            width: window.innerWidth,
            warningInfo: {
                show: false,
                message: 'No se encontraron resultados'
            },
            lastSearch: {
                range: {
                    start: null,
                    end: null
                },
                buscadorInput: null
            },
            lastSearchIsByLocation: false,
            provinciasName: "",
            apiDeVentasActive: false,
            apiDeVentasUrl: "",
            paquetes: [],
            servicios: [],
            mostrandoResultados: false
        }
    },
    setup() {

    },
    methods: {
        getPrice(precioOrientativo) {
            if (precioOrientativo) {
                if (isNaN(Number(precioOrientativo))) {
                    return '';
                } else {
                    return `${parseFloat(Number(precioOrientativo))?.toLocaleString('es-ES', { style: 'currency', currency: 'EUR' })}`
                }
            } else {
                return '';
            }
        },
        updateBuscadorInput(evt) {
            this.buscadorInput = evt.target.value;
        },
        redirectNavPath() {

        },
        goToEventos() {
            let $vm = this;
            $vm.$router.push('/eventos-singulares');
        },
        goToExperiences() {
            let $vm = this;
            $vm.$router.push('/experiencias-exclusivas');
        },
        updateSearchForm() {
            const data = {
                range: this.range,
                buscadorInput: this.buscadorInput
            };
            this.$store.commit('updateSearchData', data)
        },
        encodeUrl(name = '') {
            let rawName = name.replaceAll(',', '');
            rawName = rawName.replaceAll('.', '');

            let sanitizedName = rawName.normalize("NFD").replace(/[\u0300-\u036f]|[^0-9a-zA-Z]+[\u0020]/g, '')
            let sanitizedNameLowercase = sanitizedName.toLowerCase().replace(/[\u0020]/g, '-');

            return sanitizedNameLowercase
        },
        goToEntrada(event) {
            if (this.monumentoUrl === 'castillo-de-san-pedro-de-jaca-(ciudadela)') {
                window.open(process.env.VUE_APP_JACA_URL);
            }
            else {
                if (event.imageLabel === 'paquetes_prestadores') {
                    this.$router.push({ name: 'MicrositioPaquete', params: { 'paqueteNombre': this.encodeUrl(event.nombres[0].texto), paqueteId: event._id } })
                } else {
                    this.$router.push('/castillos/tarifas/' + this.encodeUrl(event.monumentoUrl) + '/' + event.entradaId)
                }
            }
        },
        goToMicrositioPaqueteMonumento({ name, id }) {
            console.log('🚀 ~ goToMicrositioPaqueteMonumento ~ name:', name)
            this.$router.push({ name: 'MicrositioPaqueteMonumento', params: { paqueteNombre: this.encodeUrl(name) || 'name', paqueteId: id } })
        },
        goToBuy(data, paqueteMonumento = false) {
            if (data.apiDeVentasActive && data.apiDeVentasUrl !== 'clorian') {
                this.$toast.success(this.$t("default.NOTIFICATIONS.REDIRECT"));
                setTimeout(() => {
                    window.open(`${data.apiDeVentasUrl}?idProducto=${data.oldId}`, '_blank');
                }, 2000)
            } else {

                if (data.imageLabel === 'paquetes_prestadores') {
                    this.$router.push({ name: "ReservarPackExperiencias", query: { tipo: 'paquete', id: data._id } });
                }
                else if (data.imageLabel === "servicios_prestadores") {
                    this.$router.push({ name: "ReservarPackExperiencias", query: { tipo: 'servicio', id: data._id } });
                }
                else if (paqueteMonumento) {
                    this.$router.push({ name: "ReservarPackMonumentos", query: { tipo: 'monumentos', id: data._id } });
                }
                else {
                    this.$router.push(`/castillos/reservar/idvisita:${data.entradaId}`)
                }
            }
        },
        goToEvento(event) {
            this.$router.push('/castillos/eventos/' + this.monumentoUrl + '/' + event.entradaId)
        },
        goToCastillosYPalacios() {
            let $vm = this;
            $vm.$router.push('/castillos')
        },
        goToCastillos(name) {
            let $vm = this;
            $vm.$router.push('/castillos/' + this.encodeUrl(name))
        },
        goToRoute(event) {
            this.$router.push('/ruta/' + event)
        },
        async getNearest() {
            try {
                this.loading = true;
                const _language = this.$store.getters.getIdioma;
                const { data } = await ApiService.getNearestMonuments(this.currentLocation, _language);
                // this.monumentos = [];
                this.castillos = [...data.castillos];
                this.palacios = [...data.palacios];
                this.availableMonuments = [...data.monumentos];
                const visitaSorted = [...data.visitas]
                    .filter(v => v.restringirVentaIndividual !== true)
                    .sort((a, b) => a.distance - b.distance);

                // Despues de que ordenamos las visitas por distancia, extraemos un arreglo por cada monumento
                const arrayByMonumentName = visitaSorted.reduce((acc, curr) => {
                    if (acc[curr.monumentName]) {
                        acc[curr.monumentName].push(curr);
                    } else {
                        acc[curr.monumentName] = [curr];
                    }
                    return acc;
                }, {});
                // Luego ordenamos cada array por cada tipo de filtro (tipoFiltro)
                const visitasTipoFiltro = Object.keys(arrayByMonumentName).reduce((acc, key) => {
                    acc[key] = arrayByMonumentName[key].sort((a, b) => {
                        const tipoFiltroOrder = ['Visita Libre Audioguiada', 'Visita Guiada', 'Visita Libre', 'Visita Teatralizada', 'Evento Singular'];
                        const tipoFiltroIndexA = tipoFiltroOrder.indexOf(a.tipoFiltro) !== -1 ?
                            tipoFiltroOrder.indexOf(a.tipoFiltro) :
                            0;
                        const tipoFiltroIndexB = tipoFiltroOrder.indexOf(b.tipoFiltro) !== -1 ?
                            tipoFiltroOrder.indexOf(b.tipoFiltro) :
                            0;
                        return tipoFiltroIndexA - tipoFiltroIndexB || a.distance - b.distance;
                    });
                    return acc;
                }, {});
                // Se combinan todos los arreglos al final en uno solo 
                this.visitas = Object.values(visitasTipoFiltro).flat();

                this.eventos2 = [...this.removeDuplicates(data.eventos)].filter(v => v.restringirVentaIndividual !== true);
                this.rutas = [...data.rutas];
                this.sugerencias = data.sugerencias;
                this.paquetesMonumentos = data.paquetesMonumento;


                data.servicios && (this.servicios = [...data.servicios]);
                data.paquetes && (this.paquetes = [...data.paquetes]);
                if (this.sugerencias == false) {
                    this.lastSearchIsByLocation = true;
                } else {
                    this.lastSearchIsByLocation = false;
                }
                this.loading = false;
            } catch (err) {
                this.loading = false;
            }
        },
        onSlideStart() {
            this.sliding = true
        },
        onSlideEnd() {
            this.sliding = false
        },
        toggleFullCastles() {
            if (this.showAllCastles) {
                this.$router.push('/castillos')
            }
            else {
                if (this.castillos.length <= 2) {
                    this.$router.push('/castillos')
                }
                else {
                    this.showAllCastles = true;
                }
            }
        },
        toggleFullPalaces() {
            if (this.showAllPalaces) {
                this.$router.push('/castillos')
            }
            else {
                if (this.palacios.length <= 2) {
                    this.$router.push('/castillos')
                }
                else {
                    this.showAllPalaces = true;
                }
            }
        },
        toggleFullEvents() {
            if (this.showAllEvents) {
                this.$router.push('/castillos/servicios/eventos-singulares')
            }
            else {
                if (this.visitas.length <= 2) {
                    this.$router.push('/castillos/servicios/eventos-singulares')
                }
                else {
                    this.showAllEvents = true;
                }
            }
        },
        toggleFullVisits() {
            this.showAllVisits = !this.showAllVisits;
        },
        toggleFullRoutes() {
            if (this.showAllRoutes) {
                this.$router.push('/rutas-recomendadas')
            }
            else {
                if (this.rutas.length <= 2) {
                    this.$router.push('/rutas-recomendadas');
                }
                else {
                    this.showAllRoutes = true;
                }
            }
        },

        removeDuplicates(arr) {
            return arr.filter((value, index, self) => index === self.findIndex((t) => (
                t._id === value._id
            ))
            )
        },
        async getResourcesByBuscador(e) {
            this.loading = true;

            this.saveLastSearch();

            const $vm = this;

            try {
                if (!this.buscadorInput && !this.range?.start && !this.range?.end) {
                    await $vm.getNearest()
                    setTimeout(() => {
                        document.getElementById('results')?.scrollIntoView({ behavior: 'smooth' });
                    }, 700);
                    return
                }
            } catch (error) {
                return
            }

            try {
                const _language = this.$store.getters.getIdioma;
                const { data } = await ApiService.getResourcesByBuscador(this.buscadorInput, this.range, _language);
                this.palacios = data.monumentos.filter(item => item.tipoDeMonumento === 'Palacios');
                this.castillos = data.monumentos.filter(item => item.tipoDeMonumento === 'Castillos');
                this.availableMonuments = data.monumentos;

                this.castillosProvincia = data.monumentosPorProvincia.filter(item => item.tipoDeMonumento === 'Castillos');
                this.palaciosProvincia = data.monumentosPorProvincia.filter(item => item.tipoDeMonumento === 'Palacios');

                this.provinciasName = data.provincias;

                // this.visitas = data.visitas.filter(v => v.restringirVentaIndividual !== true).splice(0, 10);
                const visitaSorted = [...data.visitas]
                    .filter(v => v.restringirVentaIndividual !== true)
                    .sort((a, b) => (a.distance ?? 0) - b.distance);

                // Despues de que ordenamos las visitas por distancia, extraemos un arreglo por cada monumento
                const arrayByMonumentName = visitaSorted.reduce((acc, curr) => {
                    if (acc[curr.monumentName]) {
                        acc[curr.monumentName].push(curr);
                    } else {
                        acc[curr.monumentName] = [curr];
                    }
                    return acc;
                }, {});
                // Luego ordenamos cada array por cada tipo de filtro (tipoFiltro)
                const visitasTipoFiltro = Object.keys(arrayByMonumentName).reduce((acc, key) => {
                    acc[key] = arrayByMonumentName[key].sort((a, b) => {
                        const tipoFiltroOrder = ['Visita Libre Audioguiada', 'Visita Guiada', 'Visita Libre', 'Visita Teatralizada', 'Evento Singular'];
                        const tipoFiltroIndexA = tipoFiltroOrder.indexOf(a.tipoFiltro) !== -1 ?
                            tipoFiltroOrder.indexOf(a.tipoFiltro) :
                            0;
                        const tipoFiltroIndexB = tipoFiltroOrder.indexOf(b.tipoFiltro) !== -1 ?
                            tipoFiltroOrder.indexOf(b.tipoFiltro) :
                            0;
                        return tipoFiltroIndexA - tipoFiltroIndexB || a.distance - b.distance;
                    });
                    return acc;
                }, {});
                // Se combinan todos los arreglos al final en uno solo 
                this.visitas = Object.values(visitasTipoFiltro).flat();

                this.eventos2 = this.removeDuplicates(data.eventos).filter(v => v.restringirVentaIndividual !== true);
                this.paquetesMonumentos = data.paquetesMonumento;
                this.rutas = data.rutas.splice(0, 10);
                this.sugerencias = data.sugerencias;
                this.paquetes = data.paquetes ?? [];
                this.loading = false;
                this.errorResults = false;
                setTimeout(() => {
                    document.getElementById('results')?.scrollIntoView({ behavior: 'smooth' });
                }, 800);
                this.warningInfo.show = false;
                //try {
                //    //ignora el fallo del preventDefault. Falla cuando se cierra el dropdown
                //    this.$refs.pill.closeDropdown()
                //} catch (error) {
                //    this.randomVar = error;
                //}
            } catch (err) {
                console.log("🚀 ~ getResourcesByBuscador ~ err:", { err })
                this.loading = false;
                if (err?.response?.data?.mensaje === 'No se encontraron resultados') {
                    this.errorResults = true;
                    this.warningInfo.show = true;
                }
            }
        },
        limpiarBuscador() {
            // this.buscadorInput = '';
            this.range = {
                start: null,
                end: null
            };
        },
        updateInput(e) {
            this.buscadorInput = e;
        },
        async submit() {
            if (this.disabledBtn) {
                return
            }
            try {
                await this.getResourcesByBuscador();
                this.mostrandoResultados = true;
                console.log('Resultados encontrados');
            } catch (err) {
                console.log(err);
            }
        },
        closeWarning(boolean) {
            console.log("🚀 ~ closeWarning ~ boolean:", boolean)
            this.warningInfo.show = false;
        },
        saveLastSearch() {
            this.lastSearch = {
                range: this.range,
                buscadorInput: this.buscadorInput
            }
        },
        lastSearchIsEqual() {
            return this.lastSearch.range?.start == this.range?.start && this.lastSearch?.range?.end == this.range?.end && this.lastSearch.buscadorInput == this.buscadorInput
        },
        getMatchingTipoMonumentos(tipoMonumento) {
            const matchingMonuments = this.availableMonuments.filter(m => m.tipoDeMonumento === tipoMonumento);
            return matchingMonuments;
        },


    },
    computed: {
        ...mapState(['searchData']),
        selectedIdiomaIndex() {
            let $vm = this;
            let idiomaIndex = 0;
            if ($vm.$i18n.locale == 'es') {
                idiomaIndex = 0;
            }
            else if ($vm.$i18n.locale == 'en') {
                idiomaIndex = 1;
            }
            else if ($vm.$i18n.locale == 'fr') {
                idiomaIndex = 2;
            }
            else if ($vm.$i18n.locale == 'de') {
                idiomaIndex = 3;
            }
            return idiomaIndex;
        },
        selectedIdioma() {
            let $vm = this;
            console.log(this.$i18n.locale);
            return $vm.$i18n.locale;
        },
        monumentos() {
            const monumentos = {}

            const result = { castillos: [], palacios: [] }

            this.visitas.forEach(visita => {
                if (!monumentos[visita.monumentoId]) {
                    monumentos[visita.monumentoId] = visita.monumentoDetails
                }
            })

            for (const monument in monumentos) {
                if (monumentos[monument].tipoDeMonumento === 'Castillos') {
                    result.castillos.push(monumentos[monument])
                }
                else if (monumentos[monument].tipoDeMonumento === 'Palacios') {
                    result.palacios.push(monumentos[monument])
                }
            }

            return result
        },
        imagenes() {
            let imgMonumentos = []

            imgMonumentos.push(...this.castillos.map((castillo) => {
                return {
                    url: `${this.FILES_URL}/monumentos/${castillo.oldId}/${castillo.banner}`
                }
            }))

            imgMonumentos.push(...this.castillosProvincia.map((castillo) => {
                return {
                    url: `${this.FILES_URL}/monumentos/${castillo.oldId}/${castillo.banner}`
                }
            }))

            imgMonumentos.push(...this.palacios.map(palacio => {
                return {
                    url: `${this.FILES_URL}/monumentos/${palacio.oldId}/${palacio.banner}`
                }
            }))

            imgMonumentos.push(...this.palaciosProvincia.map(palacio => {
                return {
                    url: `${this.FILES_URL}/monumentos/${palacio.oldId}/${palacio.banner}`
                }
            }))

            if (imgMonumentos.length >= 1 && imgMonumentos.length < 3) {
                imgMonumentos.push(...this.visitas?.map(evento => {
                    return {
                        url: `${this.FILES_URL}/visitas_castillos/${evento?.oldId}/${evento?.banner}`
                    }
                }))
            }

            if (imgMonumentos.length == 0) {
                imgMonumentos.push(...this.defaultImages)
            }


            return imgMonumentos
        },
        disabledBtn() {

            if ((this.buscadorInput === '' && this.range?.start == null && this.range?.end == null) && this.lastSearchIsByLocation == false) {
                return false
            }

            if (this.buscadorInput.length > 0 && this.buscadorInput.length < 3) {
                return true
            }

            //deshabilitamos el botón si los filtros son iguales a la ultima búsqueda
            return (this.lastSearch.range?.start == this.range?.start && this.lastSearch.range?.end == this.range?.end && this.lastSearch?.buscadorInput == this.buscadorInput) ?? false
        },
        changeColorBtn() {
            return (this.range?.start != null && this.range?.end != null)
        },
        tooltip() {
            if (this.lastSearchIsEqual()) {
                return this.$t('default.HOME_BROWSER_TOOLTIP')
            }

            if (this.buscadorInput.length > 0 && this.buscadorInput.length < 3) {
                return this.$t('default.HOME_BROWSER_TOOLTIP_2')
            }

            return ''
        }
    },
    async mounted() {
        let $vm = this;
        this.imagenesUrl = process.env.VUE_APP_IMAGES_URL;
        // $vm.monumentoNombre = $vm.$route.params.monumentoNombre;
        $vm.monumentoUrl = $vm.$route.params.monumentoNombre;
        $vm.loading = true;

        // await this.getNearest();

        // navigator.geolocation.getCurrentPosition( position => {
        //     this.currentLocation = {
        //         lat: position.coords.latitude,
        //         lng: position.coords.longitude
        //     }
        //     this.getNearest( );
        // })

        const options = {
            timeout: 5000
        }
        // this.buscadorInput = this.searchData?.buscadorInput;
        // this.range = this.searchData?.range;
        navigator.geolocation.getCurrentPosition(async (position) => {
            this.currentLocation = {
                lat: position.coords.latitude,
                lng: position.coords.longitude
            }
            await this.getNearest();
        }, async (err) => {
            console.log(err);
            this.currentLocation = {
                lat: '',
                lng: ''
            };
            await this.getNearest();
        },
            options)

        this.paramsFilter = { ...this.$store.state.paramsFilter }

        if ($vm.width > 1500) {
            $vm.clusterSize = 4;
        }

        else if ($vm.width > 1200) {
            $vm.clusterSize = 3;
        }

        else if ($vm.width >= 768) {
            $vm.clusterSize = 2
        }

        else {
            $vm.clusterSize = 1;
        }

        const _language = this.$store.getters.getIdioma;
        ApiService.getTiposDeMonumento(_language).then(result => {
            this.availableTiposDeMonumento = result.data.map(t => {
                return ({ webName:t.webName, value: t.value});
            });
        });
        ApiService.getEventosProximos(_language)
            .then(result => {
                $vm.eventos = [...result.data.filter(v => v.restringirVentaIndividual !== true)];

                $vm.eventos.sort(function (a, b) {
                    if (b.fechas.length > 0 && a.fechas.length > 0) {
                        return new Date(a.fechas[0]) - new Date(b.fechas[0])
                    }
                    else if (b.fechas.length > 0 && a.fechas.length == 0) {
                        return 1;
                    }
                    else if (b.fechas.length == 0 && a.fechas.length > 0) {
                        return -1;
                    }
                    else if (b.fechas.length == 0 && a.fechas.length == 0) {
                        if (a.fechaInicioEvento === undefined && b.fechaInicioEvento) {
                            return 1;
                        }
                        else if (a.fechaInicioEvento && b.fechaInicioEvento) {
                            return new Date(a.fechaInicioEvento) - new Date(b.fechaInicioEvento);

                        }
                        else {
                            return -1;
                        }
                    }
                    else {
                        return 0;
                    }
                })

                let eventosLength = $vm.eventos.length;

                let numberOfClusters = (eventosLength / $vm.clusterSize)

                for (let index = 0; index < numberOfClusters; index++) {
                    let eventoCluster = [];
                    for (let i = 0; i < this.clusterSize; i++) {
                        let tempEvent = $vm.eventos[i + (index * this.clusterSize)]
                        if (tempEvent != undefined)
                            eventoCluster.push(tempEvent);
                    }

                    if (eventoCluster.length < this.clusterSize) {
                        let eventosRestantes = this.clusterSize - eventoCluster.length;
                        // console.log(eventosRestantes);
                        for (let i = 0; i < eventosRestantes; i++) {
                            let tempEvent = $vm.eventos[i]
                            if (tempEvent != undefined)
                                eventoCluster.push(tempEvent);
                        }
                    }
                    $vm.eventosClusters.push(eventoCluster);
                }

                // console.log($vm.eventosClusters);
                // console.log($vm.eventos);
            })
            .catch(err => {
                console.log(err);
            });

        ApiService.getExperienciasProximas(_language)
            .then(result => {
                $vm.experiencias = [...result.data.filter(v => v.restringirVentaIndividual !== true)];

                let experienciasLength = $vm.experiencias.length;

                let numberOfClusters = (experienciasLength / $vm.clusterSize)

                for (let index = 0; index < numberOfClusters; index++) {
                    let experienciaCluster = [];
                    for (let i = 0; i < this.clusterSize; i++) {
                        let tempExp = $vm.experiencias[i + (index * this.clusterSize)]
                        if (tempExp != undefined)
                            experienciaCluster.push(tempExp);
                    }
                    // Revisamos que todos los grupos de experiencias tengan el mismo tamaño
                    if (experienciaCluster.length < this.clusterSize) {
                        let experienciasRestantes = this.clusterSize - experienciaCluster.length;
                        // console.log(experienciasRestantes);
                        // Agregamos el número de experiencias que hagan falta
                        for (let i = 0; i < experienciasRestantes; i++) {
                            let tempExp = $vm.experiencias[i]
                            if (tempExp != undefined)
                                experienciaCluster.push(tempExp);
                        }
                    }

                    $vm.experienciasClusters.push(experienciaCluster);
                }
            })
            .catch(err => {
                console.log(err);
            });

    },
    watch: {
        range(newVal, oldVal) {
            if (newVal) {
                if (newVal.start && newVal.end) {
                    setTimeout(() => {
                        try {
                            this.$refs.pill.closeDropdown();
                        } catch (error) {
                            this.err = error;
                        }
                    }, 600);
                }
            }
        },
        // buscadorInput() {
        //     this.updateSearchForm();
        // },
        // 'range.start'() {
        //     this.updateSearchForm();
        // },
        // 'range.end'() {
        //     this.updateSearchForm();
        // }
    }
}
</script>


<style scoped>
.mobile-cards-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 15px;
    column-gap: 15px;
    margin-bottom: 15px;
}

.disable-selector {
    color: rgba(225, 225, 225);
}

.image-header {
    position: absolute;
    height: 25vh;
    width: 100%;
    background-position: center;
    /* background-image: url('../assets//MONUMENTOS_.jpg'); */
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.85) 0%, rgba(133, 11, 11, 0.85) 100%);
}

.image-header-container {
    background: url('../assets//MONUMENTOS_.jpg'), lightgray 50% / cover repeat;
    width: 100%;
    height: 25vh;
}

.header-text {
    font-size: 14px;
    color: #FFF;
    font-weight: 400;
    font-style: normal;
    font-family: Montserrat;
    max-height: 80%;
    max-width: 85%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.text-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    -webkit-line-clamp: 4;
    line-clamp: 4;
}

.header-title {
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.read-more-btn {
    width: 120px;
    height: 35px;
    flex-shrink: 0;
    border-radius: 20px;
    background: var(--RUTA, #BF5B5B);
    color: #FFF;
    text-align: center;
    font-family: 'Montserrat';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border: none;
    padding: 0;
}

.thumbnails-container {
    transition: all 0.5s;
}

.thumbnail-slide {
    /* position:absolute; */
    transform: translate(0px);
    transition: all 0.5s;
}

@media (min-width: 768px) {
    .text-columns {
        column-count: 2;
        /* height: 50vh; */
    }

    .carousel-spainhn {
        height: 528px;
        width: auto;
        /* max-width: 100vw; */
    }
}

@media (max-width: 768px) {
    .text-columns {
        column-count: 2;
        /* height: 50vh; */
    }

    .carousel-homeslide {
        height: 300px !important;
    }

    .carousel-spainhn {
        height: 100%;
        width: 100% !important;
        max-width: 100vw;
    }

}

@media (max-width: 424px) {
    .text-columns {
        column-count: 1;
        /* height: 50vh; */
    }
}

#monumentosShow___BV_indicators_>ol {
    margin: 0px !important;
}

.html-container {
    position: relative;
    overflow: hidden;
    max-height: 320px;
}

.html-container p span {
    color: black !important;
}

.html-container p {
    color: black !important;
}

.read-more-btn {
    width: 120px;
    height: 35px;
    flex-shrink: 0;
    border-radius: 20px;
    background: #D9D9D9;
    color: #FFF;
    text-align: center;
    font-family: 'Montserrat';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border: none;
    padding: 0;
}

.welcome-message {
    color: #FFF;
    text-align: center;
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    overflow-wrap: break-word;
    margin: 0;
}

@media only screen and (min-width: 768px) {
    .welcome-message {
        font-size: 25px;
        /* Adjust the font size for medium screens */
        padding: 10px 0;
    }
}

.card-container {
    position: relative;
    overflow: hidden;
    max-height: 270px;
}

.rounded-end-pill::after {
    content: none;
}

.color-corp {
    color: var(--spainhn-rojo-corporativo);
}

.slider-img-2 {
    width: 100%;
    height: 100%;
    object-fit: cover;

}
</style>

<style>
.input-custom>input {
    padding: 0 !important;
    font-size: 13px;
    color: var(--spainhn-rojo-corporativo) !important;
}

.input-custom>input::placeholder {
    color: var(--spainhn-rojo-corporativo) !important;
    font-size: 13px;
    text-align: center;
}

.showResult {
    opacity: 1 !important;
    transition: all linear 0.4s;
}


.carousel-3d-controls>a {
    color: var(--spainhn-rojo-corporativo) !important;

}


/* usar solo cuando el slider (carousel) tenga 3 elementos */
/* .blur-slider{
    opacity: 0.5 !important;
} */

@media(max-width:1440px) {
    .spainhm-main .spainhn-text {
        font-size: 16px;
    }
}

@media(max-width:1400px) {
    .py-xxl-5 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important;
    }
}

@media(max-width:991px) {
    .spainhm-main .border-bottom {
        border-bottom: 0 !important;
    }
}

@media(max-width:767px) {
    .spainhm-main .py-xxl-5 {
        padding-left: 20px;
        padding-right: 20px;
    }

    .py-xxl-5 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
    }

    .spainhn-cards {
        padding-right: 20px !important;
        padding-left: 20px !important;
    }

    .mess-box h4 {
        font-size: 17px;
    }

    .slider-cards .spainhn-button-gray {
        margin-left: 0 !important;
        font-size: 18px;
    }
}
</style>