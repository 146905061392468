<template>
	<form
		@submit.prevent="submit"
		autocomplete="off"
		class="buscador-container d-flex flex-row-reverse position-relative"
	>
		<mdb-tooltip
			:maxWidth="130"
			:disabled="!disabled"
			sm
			:options="{ placement: 'top' }"
		>
			<span slot="tip">{{ tooltip }}</span>
			<button
				slot="reference"
				color="secondary"
				:class="{ disabled2: disabled }"
				type="submit"
				class="px-2 px-md-4 py-3 text-white rounded-end-pill border-0"
				style="
					background-color: #850b0b;
					font-size: 15px;
					font-weight: 700;
				"
			>
				<span>{{ $t("default.SEARCH") }}</span>
			</button>
		</mdb-tooltip>
		<div class="flex-grow-1 position-relative bg-light">
			<input
				class="border-0 input w-100 h-100"
				:placeholder="$t('default.FILTER.DESTINY_OR_ACTIVITY')"
				size="sm"
				v-model="value"
				@focus="() => focusFunction()"
				@blur="isFocused = false"
				id="search"
				name="search"
				type="text"
				autocomplete="off"
				autocorrect="off"
				autocapitalize="none"
				spellcheck="false"
			/>
			<transition>
				<h5
					v-if="warningInfo.show"
					class="pill-warning mx-1 mx-md-2 position-absolute w-100 text-start p-2 rounded rounded-pill text-white m-0"
					style="
						background-color: var(--spainhn-rojo-corporativo);
						top: 70%;
						max-width: 95%;
					"
				>
					{{ warningInfo?.message }}
				</h5>
			</transition>
		</div>
		<div>
			<mdb-dropdown
				dropright
				style="
					height: 100%;
					width: 100%;
					background-color: transparent !important;
					box-shadow: none !important;
				"
				multiLevel
				ref="main"
			>
				<mdb-dropdown-toggle
					:class="
						!changeColorBtn
							? 'bg-light-btn'
							: 'spainhn-gray-subheader'
					"
					class="rounded-start-pill pe-2 dropdownBtn d-flex align-items-center"
					slot="toggle"
					hover
					ref="dropdownButton"
					style="
						width: 100%;
						height: 100% !important;
						border: #850b0b 1px !important;
					"
				>
					<img
						:class="{ 'd-none': changeColorBtn }"
						src="/calendar.svg"
						alt="icon"
						style="width: 20px; height: 20px"
					/>
					<font-awesome-icon
						:class="{ 'd-none': !changeColorBtn }"
						style="width: 20px; height: 20px"
						icon="fa-calendar-check"
					/>
				</mdb-dropdown-toggle>
				<mdb-dropdown-menu class="shadow">
					<slot ref="panel"> </slot>
				</mdb-dropdown-menu>
			</mdb-dropdown>
		</div>
		<transition name="fade">
			<ais-instant-search
				:search-client="searchClient"
				index-name="monumentos"
				class="mt-3 rounded rounded-5 p-2 color-corp"
				@focusin="isFocused = true"
				v-show="isFocused && value.length > 2"
			>
				<ais-configure
					:hits-per-page.camel="5"
					:filters="'activo:true AND NOT tipoDeMonumento:Parador'"
				></ais-configure>
				<ais-search-box v-show="false" :value="value"></ais-search-box>
				<header class="m-0 p-0">
					<h5 class="section-title">
						{{
							!value ? "Explora Nuestro Contenido" : $t('default.HOME_BROWSER_ALGOLIA_HEADER_RESULTS')
						}}
					</h5>
				</header>
				<hr class="my-2" />

				<div
					class="d-block text-start text-sm-center d-md-flex flex-column justify-content-start algolia-body"
				>
					<!-- Visitas libres audioguiadas -->
					<section class="mt-sm-0 p-0 ais-index-1">
						<ais-index index-name="visitas">
							<ais-state-results>
								<template v-slot="{ results: { hits } }">
									<div v-show="hits.length !== 0">
										<h5 class="mb-3 section-title">
											<font-awesome-icon
												icon="fa-chess-rook"
												size="1x"
												class="text-danger"
											></font-awesome-icon>
											{{$t('default.HOME_BROWSER_ALGOLIA_HEADER_FREE_VISITS_AUDIO_GUIDES')}}
										</h5>
									</div>
								</template>
							</ais-state-results>
							<ais-configure
								filters="monumentoActivo:true AND web:true AND tipoFiltro:'Visita Libre Audioguiada'"
							></ais-configure>
							<ais-hits>
								<template v-slot:item="{ item }">
									<router-link
										tag="div"
										class="color-corp"
										:to="{
											name: 'Detalle de Entradas',
											params: {
												monumentoNombre: encodeUrl(
													item.castilloNombres?.[0]
														?.texto
												),
												entradaId: item.objectID,
											},
										}"
									>
										<p
											class="m-0"
											style="line-height: 20px"
										>
											<strong>{{
												getNameInLanguage( item.nombres, "texto" )
											}}</strong>
										</p>
										<span>{{ item.provincia }}</span>
									</router-link>
								</template>
							</ais-hits>
						</ais-index>
					</section>

					<!-- Visita guiada -->
					<section class="mt-sm-0 p-0 ais-index-1">
						<ais-index index-name="visitas">
							<ais-state-results>
								<template v-slot="{ results: { hits } }">
									<div v-show="hits.length !== 0">
										<h5 class="mb-3 section-title">
											<font-awesome-icon
												icon="fa-chess-rook"
												size="1x"
												class="text-danger"
											></font-awesome-icon>
											{{$t('default.HOME_BROWSER_ALGOLIA_HEADER_GUIDED_VISITS')}}
										</h5>
									</div>
								</template>
							</ais-state-results>
							<ais-configure
								filters="monumentoActivo:true AND web:true AND tipoFiltro: 'Visita Guiada'"
							></ais-configure>
							<ais-hits>
								<template v-slot:item="{ item }">
									<router-link
										tag="div"
										class="color-corp"
										:to="{
											name: 'Detalle de Entradas',
											params: {
												monumentoNombre: encodeUrl(
													item.castilloNombres?.[0]
														?.texto
												),
												entradaId: item.objectID,
											},
										}"
									>
										<p
											class="m-0"
											style="line-height: 20px"
										>
											<strong>{{
												getNameInLanguage( item.nombres, "texto" )
											}}</strong>
										</p>
										<span>{{ item.provincia }}</span>
									</router-link>
								</template>
							</ais-hits>
						</ais-index>
					</section>
					
					<!-- Visitas libres -->
					<section class="mt-sm-0 p-0 ais-index-1">
						<ais-index index-name="visitas">
							<ais-state-results>
								<template v-slot="{ results: { hits } }">
									<div v-show="hits.length !== 0">
										<h5 class="mb-3 section-title">
											<font-awesome-icon
												icon="fa-chess-rook"
												size="1x"
												class="text-danger"
											></font-awesome-icon>
											{{$t('default.HOME_BROWSER_ALGOLIA_HEADER_FREE_VISITS')}}
										</h5>
									</div>
								</template>
							</ais-state-results>
							<ais-configure
								filters="monumentoActivo:true AND web:true AND tipoFiltro:'Visita Libre'"
							></ais-configure>
							<ais-hits>
								<template v-slot:item="{ item }">
									<router-link
										tag="div"
										class="color-corp"
										:to="{
											name: 'Detalle de Entradas',
											params: {
												monumentoNombre: encodeUrl(
													item.castilloNombres?.[0]
														?.texto
												),
												entradaId: item.objectID,
											},
										}"
									>
										<p
											class="m-0"
											style="line-height: 20px"
										>
											<strong>{{
												getNameInLanguage( item.nombres, "texto" )
											}}</strong>
										</p>
										<span>{{ item.provincia }}</span>
									</router-link>
								</template>
							</ais-hits>
						</ais-index>
					</section>

					<!-- Visita teatralizada -->
					<section class="mt-sm-0 p-0 ais-index-1">
						<ais-index index-name="visitas">
							<ais-state-results>
								<template v-slot="{ results: { hits } }">
									<div v-show="hits.length !== 0">
										<h5 class="mb-3 section-title">
											<font-awesome-icon
												icon="fa-chess-rook"
												size="1x"
												class="text-danger"
											></font-awesome-icon>
											{{ $t('default.HOME_BROWSER_ALGOLIA_DRAMATIZED_VISIT') }}
										</h5>
									</div>
								</template>
							</ais-state-results>
							<ais-configure
								filters="monumentoActivo:true AND web:true AND tipoFiltro:'Visita Teatralizada'"
							></ais-configure>
							<ais-hits>
								<template v-slot:item="{ item }">
									<router-link
										tag="div"
										class="color-corp"
										:to="{
											name: 'Detalle de Entradas',
											params: {
												monumentoNombre: encodeUrl(
													item.castilloNombres?.[0]
														?.texto
												),
												entradaId: item.objectID,
											},
										}"
									>
										<p
											class="m-0"
											style="line-height: 20px"
										>
											<strong>{{
												getNameInLanguage( item.nombres, "texto" )
											}}</strong>
										</p>
										<span>{{ item.provincia }}</span>
									</router-link>
								</template>
							</ais-hits>
						</ais-index>
					</section>

					<!-- Eventos singulares -->
					<section class="mt-sm-0 p-0 ais-index-1">
						<ais-index index-name="visitas">
							<ais-state-results>
								<template v-slot="{ results: { hits } }">
									<div v-show="hits.length !== 0">
										<h5 class="mb-3 section-title">
											<font-awesome-icon
												icon="fa-chess-rook"
												size="1x"
												class="text-danger"
											></font-awesome-icon>
											{{$t('default.HOME_BROWSER_ALGOLIA_UNIQUE_VISITS')}}
										</h5>
									</div>
								</template>
							</ais-state-results>
							<ais-configure
								filters="monumentoActivo:true AND web:true AND tipoFiltro:'Evento Singular'"
							></ais-configure>
							<ais-hits>
								<template v-slot:item="{ item }">
									<router-link
										tag="div"
										class="color-corp"
										:to="{
											name: 'Detalle de Entradas',
											params: {
												monumentoNombre: encodeUrl(
													item.castilloNombres?.[0]
														?.texto
												),
												entradaId: item.objectID,
											},
										}"
									>
										<p
											class="m-0"
											style="line-height: 20px"
										>
											<strong>{{
												getNameInLanguage( item.nombres, "texto" )
											}}</strong>
										</p>
										<span>{{ item.provincia }}</span>
									</router-link>
								</template>
							</ais-hits>
						</ais-index>
					</section>

					<section class="mt-sm-0 p-0 ais-index-1">
						<ais-index index-name="eventos">
							<ais-state-results>
								<template v-slot="{ results: { hits } }">
									<div v-show="hits.length !== 0">
										<h5 class="mb-3 section-title">
											<font-awesome-icon
												icon="fa-chess-rook"
												size="1x"
												class="text-danger"
											></font-awesome-icon>
											{{ $t('default.HOME_BROWSER_ALGOLIA_EVENTS') }}
										</h5>
									</div>
								</template>
							</ais-state-results>
							<ais-configure
								:filters="'monumentoActivo:true AND web:true'"
							></ais-configure>
							<ais-hits>
								<template v-slot:item="{ item }">
									<router-link
										tag="div"
										class="color-corp"
										:to="{
											name: 'Detalle de Entradas',
											params: {
												monumentoNombre: encodeUrl(
													item.castilloNombres?.[0]
														?.texto
												),
												entradaId: item.objectID,
											},
										}"
									>
										<p
											class="m-0"
											style="line-height: 20px"
										>
											<strong>{{
												getNameInLanguage( item.nombres, "texto" )
											}}</strong>
										</p>
										<span>{{ item.provincia }}</span>
									</router-link>
								</template>
							</ais-hits>
						</ais-index>
					</section>

					<section>
						<ais-index index-name="paquetes_monumentos">
							<ais-state-results>
								<template v-slot="{ results: { hits } }">
									<div v-show="hits.length !== 0">
										<h5 class="mb-3 section-title">
											<font-awesome-icon
												icon="fa-chess-rook"
												size="1x"
												class="text-danger"
											></font-awesome-icon>
											{{ $t("default.HOME_NUESTROS_PAQUETES") }}
										</h5>
									</div>
								</template>
							</ais-state-results>
							<ais-configure
								:filters="'monumentoActivo:true'"
							></ais-configure>
							<ais-hits>
								<template v-slot:item="{ item }">
									<router-link
										tag="div"
										class="color-corp"
										:to="{
											name: 'MicrositioPaqueteMonumento',
											params: {
												paqueteNombre: encodeUrl(
													item.name
												),
												paqueteId: item.objectID,
											},
										}"
									>
										<p
											class="m-0"
											style="line-height: 20px"
										>
											<strong>{{ getNameInLanguage( item.nombres, "texto" )}}</strong>
										</p>
										<span>{{ item.provincia }}</span>
									</router-link>
								</template>
							</ais-hits>
						</ais-index>
					</section>

					<section class="mt-sm-0 p-0 ais-index-1">
						<ais-state-results>
							<template v-slot="{ results: { hits } }">
								<div v-show="hits.length !== 0">
									<h5 class="mb-3 section-title">
										<font-awesome-icon
											icon="fa-chess-rook"
											size="1x"
											class="text-danger"
										></font-awesome-icon>
										{{$t('default.HOME_BROWSER_ALGOLIA_KNOW_OUR_MONUMENTS')}}
									</h5>
								</div>
							</template>
						</ais-state-results>
						<ais-hits>
							<template v-slot:item="{ item }">
								<router-link
									tag="div"
									class="color-corp"
									:to="{
										name: 'Micro_Sitios',
										params: {
											monumentoNombre: encodeUrl(
												item.nombre
											),
										},
									}"
								>
									<p
										class="m-0"
										style="line-height: 20px !important"
									>
										<strong>{{ getNameInLanguage( item.nombres, "texto" ) }}</strong>
									</p>
									<span>{{ item.provincia }}</span>
								</router-link>
							</template>
						</ais-hits>
					</section>

					<section class="mt-sm-0 p-0 ais-index-1">
						<ais-index index-name="experiencias">
							<ais-state-results>
								<template v-slot="{ results: { hits } }">
									<div v-show="hits.length !== 0">
										<h5 class="mb-3 section-title">
											<font-awesome-icon
												icon="fa-chess-rook"
												size="1x"
												class="text-danger"
											></font-awesome-icon>
											{{$t('default.HOME_BROWSER_ALGOLIA_EXPERIENCES')}}
										</h5>
									</div>
								</template>
							</ais-state-results>
							<ais-configure
								:filters="'monumentoActivo:true AND web:true'"
							></ais-configure>
							<ais-hits>
								<template v-slot:item="{ item }">
									<router-link
										tag="div"
										class="color-corp"
										:to="{
											name: 'Detalle de Entradas',
											params: {
												monumentoNombre: encodeUrl(
													item.castilloNombres?.[0]
														?.texto
												),
												entradaId: item.objectID,
											},
										}"
									>
										<p
											class="m-0"
											style="line-height: 20px"
										>
											<strong>{{
												getNameInLanguage( item.nombres, "texto" )
											}}</strong>
										</p>
										<span>{{ item.provincia }}</span>
									</router-link>
								</template>
							</ais-hits>
						</ais-index>
					</section>

					<section class="mt-sm-0 p-0 ais-index-1">
						<ais-index index-name="rutas">
							<ais-state-results>
								<template v-slot="{ results: { hits } }">
									<div v-show="hits.length !== 0">
										<h5 class="mb-3 section-title">
											<font-awesome-icon
												icon="fa-chess-rook"
												size="1x"
												class="text-danger"
											></font-awesome-icon>
											{{ $t('default.HOME_BROWSER_ALGOLIA_KNOW_OUR_RUTES') }}
										</h5>
									</div>
								</template>
							</ais-state-results>
							<ais-configure
								:filters="'activa:true'"
							></ais-configure>
							<ais-hits>
								<template v-slot:item="{ item }">
									<router-link
										tag="div"
										class="color-corp"
										:to="{
											name: 'Ruta',
											params: {
												rutaId: item.objectID,
											},
										}"
									>
										<p
											class="m-0"
											style="line-height: 20px"
										>
											<strong>{{
												getNameInLanguage( item.nombres, "texto" )
											}}</strong>
										</p>
										<span>{{
											item.comunidadesPorMonumento.join(
												","
											)
										}}</span>
									</router-link>
								</template>
							</ais-hits>
						</ais-index>
					</section>
				</div>
			</ais-instant-search>
		</transition>
	</form>
</template>

<script>
import algoliasearch from "algoliasearch/lite";
import "instantsearch.css/themes/algolia-min.css";
import { getPropertyByPath } from "instantsearch.js/es/lib/utils";
import router from "../router";

export default {
	name: "BuscadorNewDesign",
	props: {
		value: {
			type: String,
			default: "",
		},
		changeColorBtn: {
			type: Boolean,
			default: false,
		},
		warningInfo: {
			type: Object,
			default: () => ({
				show: false,
				message: "",
			}),
		},
		tooltip: {
			type: String,
			default: "",
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			widthDropdownMenu: 0,
			idTimeout: null,
			searchClient: algoliasearch(
				process.env.VUE_APP_ALGOLIA_APP_ID,
				process.env.VUE_APP_ALGOLIA_API_SEARCH_KEY
			),
			isFocused: false,
		};
	},
	methods: {
		encodeUrl(name = "Hola mundo soy nuevo") {
			let rawName = name.replaceAll(",", "");
			rawName = rawName.replaceAll(".", "");

			let sanitizedName = rawName
				.normalize("NFD")
				.replace(/[\u0300-\u036f]|[^0-9a-zA-Z]+[\u0020]/g, "");
			let sanitizedNameLowercase = sanitizedName
				.toLowerCase()
				.replace(/[\u0020]/g, "-");

			return sanitizedNameLowercase;
		},
		getPropertyByPath,
		closeDropdown() {
			this.$refs.main.handleToggle();
		},
		submit() {
			this.$emit("submit");
			this.isFocused = false;
		},
		focusFunction() {
			this.isFocused = true;
			// this.scrollToPosition();
		},
		scrollToPosition() {
			if (window.innerWidth < 500) {
				window.scrollTo({
					top: 220,
					behavior: "smooth",
				});
			} else if (window.innerWidth < 768) {
				window.scrollTo({
					top: 334,
					behavior: "smooth",
				});
			} else {
				window.scrollTo({
					top: 380,
					behavior: "smooth",
				});
			}
		},
		getNameInLanguage( descriptions, label="texto" ){
			const targetLanguage = this.$store.getters.getIdioma ?? "es";

			try{

				if( descriptions ){
					const targetText = descriptions.find( d => d.idioma === targetLanguage )?.[label];
		
					if( targetText ){
						return targetText;
					}
		
					const defaultText = descriptions[0]?.[label] ?? "";
					return defaultText
				}else{
					return "";
				}
			}catch( error ){
				console.log( error );
				return ""
			}
		}
	},
	watch: {
		value( newValue, oldValue ) {
			this.$emit("input", this.value);
			if (newValue.length > 2 && oldValue.length === 2) {
				this.scrollToPosition();
			}
		},
		"warningInfo.show"() {
			if (this.idTimeout) {
				clearTimeout(this.idTimeout);
			}

			this.idTimeout = setTimeout(() => {
				console.log(
					"🚀 ~ setTimeout ~ auto-hide-warning:",
					"auto-hide-warning"
				);
				this.$emit("auto-hide-warning", false);
			}, 2800);
		},
	},

	// mounted(){
	//     this.$nextTick(() => {
	//         this.widthDropdownMenu = this.$refs.dropdownButton.$el.offsetWidth
	//     })
	// }
};
</script>

<style scoped>
.algolia-body > section {
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
	opacity: 0;
}

.rounded-start-pill {
	border-radius: 40px 0 0 40px !important;
}

.rounded-start-pill::after {
	content: none;
}

@media (width > 768px) {
	.rounded-start-pill {
		font-size: 20px;
	}
}

.rounded-end-pill {
	border-radius: 0 40px 40px 0 !important;
}

.rounded-end-pill::after {
	content: none;
}

.w-33 {
	width: 33% !important;
}

.color-corp {
	color: var(--spainhn-rojo-corporativo);
}

.input::placeholder {
	color: var(--spainhn-rojo-corporativo) !important;
	opacity: 0.6;
	font-weight: bold;
}

.input {
	color: var(--spainhn-rojo-corporativo) !important;
	font-weight: bold;
	font-size: 15px;
	padding-inline: 10px;
}

@media (width > 768px) {
	.input {
		padding-inline: 20px;
		font-size: 20px;
	}
}

@media (width < 500px) {
	.pill-warning {
		font-size: 15px;
		max-width: none !important;
		width: 70vw !important;
	}
}

.input:focus {
	border: none !important;
	outline: none !important;
}

.spainhn-gray-subheader {
	background-color: var(--spainhn-rojo-corporativo) !important;
	color: #fff !important;
	box-shadow: 2px 0px 5px 1px rgba(0, 0, 0, 0.2) !important;
}

.bg-light-btn {
	background-color: #fff !important;
	color: var(--spainhn-rojo-corporativo) !important;
	box-shadow: 2px 0px 5px 1px rgba(0, 0, 0, 0.2) !important;
}

.spainhn-gray-subheader:focus,
.bg-light-btn:focus {
	outline: none !important;
	box-shadow: 2px 0px 5px 1px rgba(0, 0, 0, 0.2) !important;
}

.pill-warning {
	text-overflow: ellipsis !important;
	overflow: hidden;
	white-space: nowrap;
	display: block;
}

.v-enter-active,
.v-leave-active {
	transition: opacity 0.5s;
}

.v-enter-to,
.v-leave {
	opacity: 1;
}

.v-enter,
.v-leave-to {
	opacity: 0;
}

.disabled2 {
	background-color: #905252 !important;
	cursor: not-allowed;
}

</style>

<style>

.section-title{
	color: #1A237E;
	font-weight: 600;
}
.tooltip-free {
	background-color: #905252 !important;
	color: #fff;
	z-index: 2;
	text-align: center;
}

.ais-SearchBox {
	margin-bottom: 1em;
}

.ais-InstantSearch {
	max-width: 960px;
	width: 100%;
	height: 515px;
	display: block;
	overflow: hidden;
	margin: 0 auto;
	position: absolute;
	top: 100%;
	left: 50%;
	transform: translateX(-50%);
	z-index: 3 !important;
	background-color: #fff !important;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
	overflow-y: auto;
	scrollbar-width: thin;
	scrollbar-color: #850b0b #fefefe;
	scroll-behavior: smooth;
	overscroll-behavior-y: contain;
}

.ais-Hits {
	width: 100%;
	padding: 0;
	display: flex;
	flex-direction: column;
	justify-content: center !important;
}

.ais-Hits-item {
	/* margin-left: 0; */
	width: 100% !important;
	background: #fefefefe;
	border: #850b0b22 1px solid;
	border-radius: 10px;
	margin: 0;
	margin-block: 4px;
	padding: 0;
	cursor: pointer;
	height: 55px;
	justify-content: center;
	overflow: hidden;
	/* overflow-y: scroll; */
	scrollbar-width: thin;
	padding: 3px;
	transition: all 0.3s ease-in-out;
	font-size: 15px !important;
	overflow-y: auto;
}

.ais-Hits-item:hover {
	background: #ac101022;

	& span {
		color: #33333399 !important;
	}
}

.ais-Hits-item span {
	transition: all 0.3s ease-in-out;
	color: #33333385 !important;
	font-weight: bold;
}

.ais-index-1 > .ais-Hits {
	width: 100%;
}

.ais-Hits-item img {
}

.ais-Hits-list {
	margin-inline: 2px;
}

@media (width < 500px) {
	.ais-SearchBox {
	}

	.ais-InstantSearch {
		font-size: 0px !important;
	}

	.ais-Hits {
	}

	.ais-Hits-item {
		height: auto;
		font-size: 11px !important;
	}

	.ais-index-1 > .ais-Hits {
	}

	.ais-Hits-item img {
	}

	.ais-Hits-list {
	}
}
</style>
